<template>
  <div class="web-container">
    123
  </div>
</template>

<script>

export default {
  name: "web_fjxxzy_sqjs",
  data() {
    return {}
  },
  created() {
  },
  mounted() {
    document.title = "苏区精神web版"
    window.location.href = "http://resouce.cdzyhd.com/experiment/webgl/sqjs/24022601/index.html"
  },
  methods: {}
}
</script>
<style scoped lang="less">

</style>
